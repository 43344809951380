


























































































































import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const form = ref<any>(null);
    const { goBack } = useMisc({ root });

    const model = reactive({
      ordinalNumber: "0",
      name: "",
      englishName: "",
      type: "registration",
      status: false,
      formType: "single",
      maxSelect: "0",
      limit: "1",
      isRequired: false,
      includeGroup: true,
      sendEmail: false,
      email: "",
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      valid: false,
    });

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
      amount: [
        (v: number) =>
          (1 <= v && v <= 10) ||
          `${root.$t("panel.event.modules.additionalService.group.limit")}`,
      ],
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc(
            "panel.event.modules.additionalService.group.error404"
          )}`;
        case 409:
          return `${root.$tc(
            "panel.event.modules.additionalService.group.error409"
          )}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`additional-service-group/${root.$route.params.asgid}`)
        .then(({ data: { additionalServiceGroup } }) => {
          model.ordinalNumber = additionalServiceGroup.ordinalNumber;
          model.name = additionalServiceGroup.name;
          model.englishName = additionalServiceGroup.englishName;
          model.type = additionalServiceGroup.type;
          model.status = additionalServiceGroup.isEnabled;
          model.formType = additionalServiceGroup.formType;
          model.maxSelect =
            additionalServiceGroup.maxSelect > 0
              ? additionalServiceGroup.maxSelect
              : 0;
          model.limit = additionalServiceGroup.limit;
          model.isRequired = additionalServiceGroup.isRequired;
          model.includeGroup = additionalServiceGroup.includeGroup;
          model.sendEmail = additionalServiceGroup.sendEmail;
          model.email = additionalServiceGroup.email;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    watch(
      () => model.formType,
      () => {
        if (model.formType === "single") {
          model.maxSelect = "0";
        }
      }
    );

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        ordinalNumber: parseFloat(model.ordinalNumber),
        name: model.name,
        englishName: model.englishName,
        type: model.type,
        isEnabled: model.status,
        formType: model.formType || undefined,
        maxSelect:
          parseFloat(model.maxSelect) > 0 ? parseFloat(model.maxSelect) : null,
        limit: model.limit || undefined,
        isRequired: model.isRequired,
        includeGroup: model.includeGroup,
        sendEmail: model.sendEmail,
        email: model.sendEmail ? model.email : null,
      };

      state.loading = true;

      axiosInstance
        .put(`additional-service-group/${root.$route.params.asgid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc(
              "panel.event.modules.additionalService.group.successEdit"
            ),
          });
          setTimeout(() => {
            goBack("panel.event.view.additionalService.list");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return { form, model, state, rules, onSubmit };
  },
});
